import styled from "styled-components"

import RightArrowIcon from "src/ui/icons/right-arrow.svg"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function PlanRenewalPeriod({
  renewedFrom,
  renewedUntil,
  loading,
}: {
  renewedFrom: string
  renewedUntil: string
  loading: boolean
}) {
  if (loading) {
    return <MSkeleton width="10ch" />
  }

  return (
    <PeriodWrapper>
      <MText>{renewedFrom}</MText>
      <RightArrowIcon width={24} />
      <MText>{renewedUntil}</MText>
    </PeriodWrapper>
  )
}

const PeriodWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS3};
  margin-top: ${spacing.XS2};
  white-space: nowrap;
`
