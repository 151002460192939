import styled from "styled-components"

import { useTranslate } from "src/i18n/useTranslate"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function PlanRenewalTotal({
  renewalAmount,
  planAmount,
  addonAmount,
  isLoading,
}: {
  renewalAmount?: string
  planAmount?: string
  addonAmount?: string
  isLoading: boolean
}) {
  const { t, langKeys } = useTranslate()

  return (
    <div>
      <TotalWrapper>
        <Total total={renewalAmount} isLoading={isLoading} />
      </TotalWrapper>
      {!isLoading && (
        <MText marginBottom={spacing.XS} color="secondary">
          ({t(langKeys.properties)}: {planAmount}, {t(langKeys.addons)}:{" "}
          {addonAmount})
        </MText>
      )}
    </div>
  )
}

function Total({ total, isLoading }: { total?: string; isLoading: boolean }) {
  const { t, langKeys } = useTranslate()

  if (isLoading) {
    return <MSkeleton width="10ch" inline />
  }

  return (
    <TotalWrapper>
      <MText variant="heading3">
        {t(langKeys.plan_renewal_estimate_breakdown_total)}:{" "}
      </MText>
      <MText variant="heading3">{total}</MText>
    </TotalWrapper>
  )
}

const TotalWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
`
