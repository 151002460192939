import {
  TGroupedPlanRenewalBreakdown,
  TPlanRenewalEstimateBreakdownResponse,
} from "src/data/billing/types/billingTypes"

/**
 * Utility function to group renewal estimation breakdown by home group
 * This returns the groups in an object where the key is a home group id
 */
export function groupPlanRenewalBreakdownByHomeGroup(
  breakdown: TPlanRenewalEstimateBreakdownResponse["breakdown"]
) {
  const groupedBreakdown = breakdown.reduce<
    Record<string, TGroupedPlanRenewalBreakdown[number]>
  >((map, item) => {
    const id = item.homegroup_id ?? ""

    const currentItem = map[id] ?? {
      homegroupName: item.homegroup_name ?? "",
      breakdown: [],
    }
    map[id] = {
      ...currentItem,
      breakdown: [...currentItem.breakdown, item],
    }

    return map
  }, {})

  return Object.values(groupedBreakdown)
}
